<template>
  <b-card :title="$i18n.messages.ReportBtn">
    <b-row>
      <!-- <b-col md="3" sm="4" class="my-1"> </b-col>
      <b-col md="3" sm="4" class="my-1"> </b-col>
      <b-col md="3" sm="4" class="my-1"> </b-col>
       <b-col md="3" class="my-1">
        <b-button variant="primary" @click="setShowModalAdd(true)">
          {{ $i18n.messages.NewReport }}
        </b-button>
      </b-col> -->

      <b-col cols="12 my-2">
        <b-table striped responsive :items="items" :fields="fields">
          <template #cell(actions)="data">
            <b-button
              class="btn-action"
              style="min-width: 100px;"
              v-if="data.item.Accion === -1"
              @click="generatePdf(data.item)"
            >
              Generar PDF
            </b-button>
            <b-button
              class="btn-action"
              style="min-width: 100px;"
              v-else
              @click="openReport(data.item)"
            >
              Ver informe
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <!--  <modal-add-report /> -->
  </b-card>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BButton,
  BCard,
  BBadge,
  BButtonGroup,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { config } from "../shared/app.config";
/* import ModalAddReport from "@/views/ModalAddReport.vue"; */

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BButton,
    BButtonGroup,
    BBadge,
    flatPickr,
    /* ModalAddReport, */
  },
  data() {
    return this.customData();
  },
  computed: {
    ...mapGetters({
      items: "reports/getListReports",
      reportUrl: "reports/getReport",
      totalItems: "reports/getTotalListReports",
      client: "customers/getCurrentCustomer",
    }),
  },
  created() {
    this.perPage = JSON.parse(localStorage.getItem("PerPageOrder")) || 10; // value or null
    this.fecha_ini = JSON.parse(localStorage.getItem("fechaIniOrder")) || null; // value or null
    this.fecha_fin = JSON.parse(localStorage.getItem("fechaFinOrder")) || null; // value or null
    this.currentPage =
      JSON.parse(localStorage.getItem("currentPageOrder")) || 1; // value or null
    this.sortBy = JSON.parse(localStorage.getItem("sortByOrder")) || ""; // value or null
    this.sortDesc = JSON.parse(localStorage.getItem("sortDescOrder")) || "+"; // value or null

    this.listReports(this.filterType);

    console.log(this.items);
  },
  watch: {
    currentPage() {
      this.listReports(this.filterType);
    },
    perPage() {
      this.listReports(this.filterType);
    },
    sortBy() {
      this.listReports(this.filterType);
    },
    sortDesc() {
      this.listReports(this.filterType);
    },
  },
  methods: {
    ...mapActions({
      searchReports: "reports/searchReports",
      getReport: "reports/getReport",
    }),
    ...mapMutations({
      setLoading: "app/setLoading",
      setShowModalAdd: "reports/setShowModalAdd",
    }),
    generatePdf(report) {
      console.log(report);
    },
    openReport(report) {
      console.log(report.ID);
      this.setLoading(true);
      this.getReport(report.ID).then(() => {
        console.log(this.reportUrl);
        this.setLoading(false);
        window.open(this.reportUrl);
      });
      //  window.open(report);
    },
    customData() {
      return {
        filterType: "client",
        fecha_ini: null,
        fecha_fin: null,
        config,
        pageOptions: [10, 15, 20],
        product: {},
        sortBy: "",
        sortDesc: "+",
        sortOptions: [
          { text: "Estado", value: "estado" },
          { text: "Date", value: "fecha" },
        ],
        orderOptions: [
          { text: "Desc", value: "+" },
          { text: "Asc", value: "-" },
        ],
        currentPage: 1,
        perPage: 10,
        sort: "",

        fields: [
          {
            key: "ID",
            label: "ID",
          },
          {
            key: "Nombre",
            label: "Título",
          },

          {
            key: "Sentencia",
            label: "Select",
          },
          {
            key: "Rol",
            label: "Rol",
          },
          /*  {
            key: "URL",
            label: "URL",
          }, */
          {
            key: "Accion",
            label: "Estado",
          },
          {
            key: "Actions",
            label: "",
          },
        ],
      };
    },
    listReports(type) {
      const { currentPage, perPage, sortBy, sortDesc } = this;

      const limit = perPage;
      const offset = (currentPage - 1) * perPage;
      let sort = "";
      if (sortBy) {
        sort = sortDesc + sortBy;
      }
      this.setLoading(true);
      this.searchReports({
        limit,
        offset,
        sort,
      }).then(() => {
        this.setLoading(false);
      });
    },
    resetFilters() {
      localStorage.removeItem("PerPageOrder");
      localStorage.removeItem("fechaIniOrder");
      localStorage.removeItem("fechaFinOrder");
      localStorage.removeItem("currentPageOrder");
      localStorage.removeItem("sortByOrder");
      localStorage.removeItem("sortDescOrder");
      Object.assign(this.$data, this.customData());
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-flatpicker.scss";
</style>
